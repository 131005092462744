<template>
  <div class="transfer-balance">
    <div class="title">转余额</div>
    <div class="icon-box" v-show="!show">
      <div>
        <i class="el-icon-circle-check icon"></i>
      </div>
      <div class="text">转余额成功</div>
    </div>
    <div class="text">当前客户本店历史余额</div>
    <div class="pirce">
      <span>￥</span><span>{{ userData.balance ? userData.balance : 0 }}</span
      ><span>元</span>
    </div>
    <div class="balance-text">
      {{
        show
          ? "余额不足，无法支付本笔订单"
          : "请返回至结算页，选择通享卡余额支付"
      }}
    </div>
    <div class="hint-text" v-show="show">
      请将历史余额转入通享卡后结算
    </div>
    <div class="button">
      <el-button
        class="button1"
        v-show="show"
        type="primary"
        @click="enterClick"
        :loading="enterLoading"
        >确定转余额</el-button
      >
      <el-button
        class="button1"
        v-show="!show"
        type="primary"
        @click="enterBack"
        >确定</el-button
      >
    </div>

    <button class="close-icon" @click="close"></button>
  </div>
</template>

<script>
import { setCashierTradeBalance } from "@/api/clothes/clothes";
export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enterLoading: false,
      show: true,
    };
  },
  created() {},
  methods: {
    enterClick() {
      let { userId, userName, phone, storeId } = this.userData;
      this.enterLoading = true;
      setCashierTradeBalance({ userId, userName, phone, storeId })
        .then(({ data }) => {
          if (data.code == 0) {
            this.show = false;
            this.enterLoading = false;
            this.$emit("enter");
          } else {
            this.$message.error(data.msg);
            this.enterLoading = false;
          }
        })
        .catch(() => {
          this.enterLoading = false;
        });
    },
    close() {
      this.$emit("close");
      this.show = true;
    },
    enterBack() {
      this.show = true;
      this.$emit("enterBack");
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-balance {
  font-size: 18px;
  text-align: center;
  color: #000;
  position: relative;
  .title {
    padding: 25px 0 15px 0;
    font-size: 22px;
    line-height: 40px;
    font-weight: 700;
  }
  .icon-box {
    font-size: 16px;
    padding-top: 60px;
    .text {
      padding: 10px;
    }
    .icon {
      font-size: 30px;
      color: #1adc25;
    }
  }
  .text {
    padding: 60px 0 20px 0;
  }
  .pirce {
    font-size: 24px;
    font-weight: 700;
    span:nth-child(1) {
      margin-right: 10px;
    }
    span:nth-child(3) {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .balance-text {
    color: #860404;
    padding: 20px 0 10px 0;
  }
  .button {
    padding-top: 100px;
    .el-button {
      width: 130px;
      height: 40px;
      font-size: 16px;
    }
  }

  .close-icon {
    position: absolute;
    top: 25px;
    right: 15px;
    width: 40px;
    height: 40px;
    background: url("~@/assets/navImages/Icon_X_Gray.png") left center / 28px
      28px no-repeat;
    border: none;
  }
}
</style>
