<template>
  <div class="productList">
    <div class="flex-row" style="cursor:pointer" @click="back">
      <i class="el-icon-back" style="font-size:18px;color:#909399"></i>
      <span style="padding-left:10px;color:#909399">返回</span>
      <span style="padding-left:10px">商品零售</span>
    </div>
    <div class="productList-content">
      <div class="productList-left">
        <div
          class="productList-item flex-row"
          v-for="item in productList"
          :key="item.id"
          @click="selectProduct(item)"
        >
          <div>
            <img :src="item.goodsImage" v-if="item.goodsImage" class="img" />
            <span
              class="img flex-center"
              style="font-size:14px;background:#f8f8f8"
              v-else
              >暂无图片</span
            >
          </div>
          <div class="text-box">
            <div style="font-size:16px">{{ item.goodsName }}</div>
            <div>库存:{{ item.surplusInventoryNum }}</div>
            <div>
              ￥{{
                userInfo.userType == "2" ? item.vipSalePrice : item.salePrice
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="productList-right">
        <div class="productList-right-top">
          <div style="padding:20px 20px 0 20px">
            <div>售卖人员: {{ userInfo.realName }}</div>
            <div style="border-bottom: 1px solid #EDEEF2;padding-bottom:10px">
              售卖时间: {{ userInfo.time }}
            </div>
            <div
              class="flex-row"
              style="padding:20px;background:#FAFAFA;border-radius: 5px"
            >
              <div style="margin-right:20px" class="flex-row">
                <el-avatar :size="65" :src="userInfo.avator"></el-avatar>
              </div>
              <div>
                <div style="font-size:14px;padding-bottom:4px">
                  {{ userInfo.realName }}
                </div>
                <div style="font-size:14px;padding-bottom:4px">
                  {{ userInfo.phone }}
                </div>
                <div class="member" v-if="userInfo.userType == '2'">
                  <el-image
                    style="width: 22px; height: 18px"
                    :src="require('@/assets/img/vip.png')"
                    :fit="'fill'"
                  ></el-image>
                  <span class="member-name">权益会员</span>
                </div>
              </div>
            </div>
            <div class="select">
              <div class="flex-row select-title">
                <span
                  class="title"
                  :style="{
                    flex: item.flex,
                  }"
                  v-for="item in titleProductList"
                  :key="item.label"
                  >{{ item.label }}</span
                >
              </div>
              <div
                class="flex-row select-item"
                @click="selectItem(item)"
                :style="{
                  marginBottom:
                    index == selectProductList.length - 1 ? '10px' : 0,
                  color: item.active ? '#3370FF' : '#000',
                }"
                v-for="(item, index) in selectProductList"
                :key="item.id"
              >
                <span
                  class="title"
                  :style="{
                    flex: item2.flex,
                  }"
                  :class="item2.label == '商品名称' ? 'hidden' : ''"
                  v-for="item2 in item.list"
                  :key="item2.label"
                >
                  <span v-if="item2.label == '数量'" class="title">
                    <el-input-number
                      style="width:90px"
                      v-model="item2.value"
                      @change="(e) => changeNum(e, item)"
                      controls-position="right"
                      :min="1"
                      :max="item2.maxValue"
                    ></el-input-number>
                  </span>
                  <span v-else-if="item2.label == ''">
                    <i
                      @click="deleteItem(item)"
                      class="el-icon-delete"
                      style="font-size:18px;color:#909399"
                    ></i>
                  </span>
                  <span v-else>{{
                    item2.label == "商品名称" ? item2.value : "￥" + item2.value
                  }}</span>
                </span>
                <span class="corner-mark">{{ index + 1 }}</span>
              </div>
            </div>
          </div>
          <div class="total-price">
            <span>已选商品: {{ selectProductList.length }}</span>
            <span style="margin-left:20px"
              >应收款:
              <span style="color:red;font-size:26px"
                >￥{{ (totalPrice / 100).toFixed(2) }}</span
              ></span
            >
          </div>
        </div>
        <div class="button">
          <el-button
            type="primary"
            @click="collect"
            :disabled="selectProductList.length == 0"
            >去收款</el-button
          >
        </div>
      </div>
    </div>
    <EnterPay
      :payDrawer="payDrawer"
      :list="selectProductList"
      :price="totalPrice"
      :user="userInfo"
      @closeDrawer="closeDrawer"
    />
  </div>
</template>

<script>
import vip from "@/assets/img/vip.png";
import EnterPay from "../enterPay";
import { getStoreGoodsPrice } from "@/api/commodity";
import moment from "moment";

export default {
  name: "productList",
  components: {
    EnterPay,
  },
  data() {
    return {
      productList: [],
      vip,
      titleProductList: [
        {
          label: "商品名称",
          flex: 1.2,
        },
        {
          label: "单价",
          flex: 1,
        },
        {
          label: "数量",
          flex: 1,
        },
        {
          label: "优惠权益",
          flex: 1,
        },
        {
          label: "小计",
          flex: 0.8,
        },
        {
          label: "",
          flex: 0.5,
        },
      ],
      selectProductList: [],
      payDrawer: false,
      loading: false,
      userInfo: {},
      totalPrice: 0,
    };
  },
  watch: {
    selectProductList: {
      handler(list) {
        this.totalPrice = 0;
        list.forEach((item) => {
          this.totalPrice = this.totalPrice + item.list[4].value * 100;
        });
      },
      deep: true,
    },
  },
  created() {
    this.userInfo = {
      ...this.$route.query,
      time: moment(new Date()).format("YYYY年MM月DD日"),
    };
    console.log(this.userInfo);
    this.getStoreGoodsPriceData();
  },
  methods: {
    // 获取门店商品类别
    getStoreGoodsPriceData() {
      this.loading = true;
      getStoreGoodsPrice()
        .then(({ data }) => {
          if (data.code == 0) {
            this.productList = data.data;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
    // 选择商品
    selectProduct(data) {
      if (data.surplusInventoryNum == 0) {
        this.$message.error("商品库存不足！");
        return;
      }
      let bool = this.selectProductList.some(
        (item) => item.goodsId == data.goodsId
      );
      if (!bool) {
        this.selectProductList.unshift({
          ...data,
          list: [
            {
              label: "商品名称",
              value: data.goodsName,
              flex: 1.2,
            },
            {
              label: "单价",
              value: data.salePrice,
              flex: 1,
            },
            {
              label: "数量",
              value: 1,
              flex: 1,
              maxValue: data.surplusInventoryNum,
            },
            {
              label: "优惠权益",
              value:
                this.userInfo.userType == "2"
                  ? (data.salePrice * 100 - data.vipSalePrice * 100) / 100
                  : 0,
              flex: 1,
            },
            {
              label: "小计",
              value:
                this.userInfo.userType == "2"
                  ? data.vipSalePrice
                  : data.salePrice,
              flex: 0.8,
            },
            {
              label: "",
              value: "",
              flex: 0.5,
            },
          ],
        });
      } else {
        this.selectProductList = this.selectProductList.map((item) => {
          if (
            item.goodsId == data.goodsId &&
            item.list[2].value < data.surplusInventoryNum
          ) {
            item.list[2].value++;
            if (this.userInfo.userType == "2") {
              item.list[4].value =
                (item.list[2].value * (item.vipSalePrice * 100)) / 100;
            } else {
              item.list[4].value =
                (item.list[2].value * (item.salePrice * 100)) / 100;
            }
          }
          return item;
        });
      }
      this.selectItem(data);
    },
    selectItem(data) {
      this.selectProductList = this.selectProductList.map((item) => {
        item.active = false;
        if (item.goodsId == data.goodsId) {
          item.active = true;
        }
        return item;
      });
    },
    // 删除商品
    deleteItem(data) {
      this.$confirm("是否要删除当前商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.selectProductList = this.selectProductList.filter(
            (item) => item.goodsId != data.goodsId
          );
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeNum(e, data) {
      this.selectProductList = this.selectProductList.map((item) => {
        if (item.goodsId == data.goodsId) {
          if (this.userInfo.userType == "2") {
            item.list[4].value = (e * (item.vipSalePrice * 100)) / 100;
          } else {
            item.list[4].value = (e * (item.salePrice * 100)) / 100;
          }
        }
        return item;
      });
    },
    collect() {
      this.payDrawer = true;
    },
    closeDrawer() {
      this.payDrawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.productList {
  padding: 20px;
  .productList-content {
    position: relative;
    .productList-right {
      width: 500px;
      height: calc(100vh - 61px);
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 5px;
      .productList-right-top {
        border: 1px solid #edeef2;
      }
      .member {
        display: flex;
        position: relative;

        .el-image {
          position: absolute;
          z-index: 99;
        }

        .member-name {
          background-color: #fbae11;
          color: #fff;
          width: 70px;
          font-size: 12px;
          margin-left: 5px;
          text-align: center;
          height: 18px;
          line-height: 18px;
          border-radius: 9px;
          padding-left: 10px;
        }
      }
      .title {
        flex: 1;
        text-align: center;
      }
      .select {
        height: calc(100vh - 380px);
        overflow: auto;
        .select-item {
          height: 80px;
          background: #f8f8f8;
          padding: 10px;
          margin-top: 10px;
          border-radius: 5px;
          font-size: 14px;
          position: relative;
          .corner-mark {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 16px;
            background-color: red;
            color: #fff;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            text-align: center;
            line-height: 16px;
            font-size: 12px;
          }
        }
        .select-title {
          padding: 10px;
          border-bottom: 1px solid #edeef2;
          color: #898989;
          font-size: 14px;
        }
      }
      .total-price {
        text-align: right;
        height: 60px;
        background-color: #f8f8f8;
        line-height: 60px;
        padding-right: 10px;
        span {
          font-size: 18px;
          color: #a4a4a4;
        }
      }
      .button {
        margin-top: 20px;
        .el-button {
          width: 500px;
          height: 50px;
          font-size: 18px;
        }
      }
    }
    .productList-left {
      display: flex;
      flex-wrap: wrap;
      margin-right: 500px;
      padding-top: 20px;
      .productList-item {
        width: 360px;
        height: 120px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid #edeef2;
        padding: 20px;
        border-radius: 5px;
        .img {
          height: 80px;
          width: 80px;
        }
        .text-box {
          padding-left: 10px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}
.flex-row {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
