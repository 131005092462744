import request from '@/utils/request'

/**
 * 查询所有的衣物类型
 */
export function getClothesCategoryList() {
  return request({
    url: '/clothes/category',
    method: 'get',
  })
}

/**
 * 根据衣物类型查询衣物信息列表
 * @param categoryId
 */
export function getClothesInfoList() {
  return request({
    url: '/clothes/info/allCategoryClothes',
    method: 'get',
  })
}

/**
 * 根据衣物Id获取衣物服务列表
 * @param userId
 * @param clothesId
 */
export function getClothesServiceList(userId, clothesId) {
  return request({
    url: '/clothes/service/info/' + userId + '/' + clothesId,
    method: 'get',
  })
}

/**
 * 查询所有的衣物颜色列表
 */
export function getClothesColorList() {
  return request({
    url: '/clothes/color/',
    method: 'get',
  })
}

/**
 * 查询所有的衣物瑕疵列表
 */
export function getClothesBlemishList() {
  return request({
    url: '/clothes/blemish/',
    method: 'get',
  })
}

/**
 * 查询所有的衣物附件列表
 */
export function getClothesAccessoryList() {
  return request({
    url: '/clothes/accessory/',
    method: 'get',
  })
}

/**
 * 查询所有的衣物附件列表
 */
export function getClothesBrandList() {
  return request({
    url: '/clothes/brand/',
    method: 'get',
  })
}

/**
 * 查询所有的衣物洗后效果列表
 */
export function getClothesEffectList() {
  return request({
    url: '/clothes/effect/',
    method: 'get',
  })
}

/**
 * 根据分类ID获取洗后效果
 * @param categoryId
 * @returns {AxiosPromise}
 */
export function getClothesEffectListByCategoryId(categoryId) {
  return request({
    url: `/clothes/effect/list/${categoryId}`,
    method: 'get',
  })
}

/**
 * 新增订单
 * @param data
 */
export function addOrder(data) {
  return request({
    url: '/order/order/',
    method: 'post',
    data: data,
  })
}

export function printPaper(type, orderNumber) {
  return request({
    url: '/order/clothesOperateRecord/printPaper/' + type + '/' + orderNumber,
    method: 'get',
  })
}

/**
 * 更新订单
 * @param data
 */
export function updateOrder(data) {
  return request({
    url: '/order/order/',
    method: 'put',
    data: data,
  })
}

/**
 * 点击自取
 */
export function clothesPick(data) {
  return request({
    url: '/order/orderclothes/pick',
    method: 'post',
    data: data,
  })
}

/**
 * 根据衣物号查询衣物信息
 * @param clothesNum
 */
export function getClothesInfo(clothesNum) {
  return request({
    url: '/order/orderclothes/' + clothesNum,
    method: 'get',
  })
}

/**
 * 添加衣物图片
 * @param data
 */
export function addClothesImages(data) {
  return request({
    url: '/order/orderclothes/addClothesImages',
    method: 'post',
    data: data,
  })
}

/**
 * 入店通过衣物号获取衣物信息
 * @param clothesNum
 */
export function getClothesByClothesNumForReWash(clothesNum, type) {
  return request({
    url: '/order/orderclothes/rewash/' + clothesNum + '/' + type,
    method: 'get',
  })
}

/**
 * 反洗记录
 * @param clothesNum
 */
export function rewashRecord(params) {
  return request({
    url: '/order/orderclothes/rewash/record',
    method: 'get',
    params,
  })
}

/**
 * 点击返洗
 * @param ids
 */
export function clothesReWash(id) {
  return request({
    url: '/order/orderclothes/rewash',
    method: 'put',
    data: id,
  })
}

export function clothesByClothesNumber(clothesNum) {
  return request({
    url: '/order/orderclothes/clothesByClothesNumber/' + clothesNum,
    method: 'get',
  })
}

/**
 * 修改超时订单
 * @param ids
 */
export function updateTimeOutClothesProcessingStatus(parms) {
  return request({
    url: '/order/timeout-clothes',
    method: 'put',
    params: parms,
  })
}

/**
 * 保存附件
 * @param parms
 */
export function addAccessory(data) {
  return request({
    url: '/clothes/accessory',
    method: 'post',
    data: data,
  })
}

/**
 * 保存颜色
 * @param parms
 */
export function addColor(data) {
  return request({
    url: '/clothes/color',
    method: 'post',
    data: data,
  })
}

/**
 * 保存品牌
 * @param parms
 */
export function addBrand(data) {
  return request({
    url: '/clothes/brand',
    method: 'post',
    data: data,
  })
}

/**
 * 保存瑕疵
 * @param parms
 */
export function addBlemish(data) {
  return request({
    url: '/clothes/blemish',
    method: 'post',
    data: data,
  })
}

/**
 * 保存草稿衣物信息
 * @param data
 */
export function addDraftbox(data) {
  return request({
    url: '/order/draftbox',
    method: 'post',
    data: data,
  })
}

/**
 * 获取草稿衣物信息
 * @param data
 */
export function listDraftbox(data) {
  return request({
    url: '/order/draftbox',
    method: 'get',
  })
}

/**
 *
 * @param phone
 */
export function queryClothesDraftboxByPhone(phone) {
  return request({
    url: '/order/draftbox/queryClothesDraftboxByPhone/' + phone,
    method: 'get',
  })
}

/**
 *
 * @param id
 */
export function deleteClothesDraftboxs(id) {
  return request({
    url: '/order/draftbox/' + id,
    method: 'delete',
  })
}

export function queryClothesDraftboxById(id) {
  return request({
    url: '/order/draftbox/queryClothesDraftboxById/' + id,
    method: 'get',
  })
}

export function getDraftboxClothesNum() {
  return request({
    url: '/order/draftbox/getDraftboxClothesNum/',
    method: 'get',
  })
}

export function clothesAccessoryList(clothesNumber) {
  return request({
    url: '/order/orderclothes/clothesAccessoryList/' + clothesNumber,
    method: 'get',
  })
}

/**
 * 修改订单
 * @param data
 */
export function calculateClothesInfo(data) {
  return request({
    url: '/order/order/calculateClothesInfo',
    method: 'post',
    data: data,
  })
}

/**
 * 根据衣物号查询格架信息
 * @param clothesNum
 */
export function getWardrobeListByClothesNum(clothesNum) {
  return request({
    url: '/order/orderclothes/getWardrobeList/' + clothesNum,
    method: 'get',
  })
}

export function updateWardrobe(params) {
  return request({
    url: '/order/orderclothes/updateWardrobe',
    method: 'post',
    params: params,
  })
}

export function updateOrderClothes(data) {
  return request({
    url: '/order/orderclothes/updateOrderClothes',
    method: 'put',
    data: data,
  })
}

// 返洗记录撤销
export const rewashRevoke = (data) => {
  return request({
    url: 'order/orderClothesRewash/deleteById',
    method: 'post',
    data
  })
}

/**
 * 获取门店已取衣列表
 */
export function takeClothesPage(params) {
  return request({
    url: '/order/orderclothes/takeClothesPage',
    method: 'get',
    params: params,
  })
}
/**
 * 获取门店已上挂列表
 */
export function hangClothesPage(params) {
  return request({
    url: '/order/orderclothes/hangClothesPage',
    method: 'get',
    params: params,
  })
}
/**
 * 获取门店送洗列表
 */
export function getStoreWash(type) {
  return request({
    url: `order/orderclothes/waitSend/operate/${type}`,
    method: 'get',
  })
}
/**
 * 获取订单提成金额
 */
export function getOrderCommission(data) {
  return request({
    url: `clothes/clothesCommission/getClothesCommission`,
    method: 'POST',
    data,
  })
}

/**
 * 立即处理
 */
export function getRight(data){
  return request({
    url:`message/notice/remind/process/callback`,
    method: 'post',
    data:data,
  })
}
/**
 * 稍后处理
 */
 export function getLater(data){
  return request({
    url:`message/notice/remind/process/callback`,
    method: 'post',
    data:data,
  })
}
/*
 * 团购查询
 */
export function inquireQuery(id,userPhone){
  return request({
    url:'/member/package/query/info/' + id + '/'+ userPhone,
    method:'get'
  })
}
//团购查询套餐衣物
export function listQuery(packageId){
  return request({
    url:'/clothes/info/allPackageCategoryClothes/' + packageId,
    method:'get'
  })
}
//套餐Id获取衣物列表
export function clothingIdentification(id,useService){
  return request({
    url:'/clothes/service/getPackageClothes/'+ id +'/'+ useService,
    method:'get'
  })
}
//团购订单校验
export function clothesCheck(data){
  return request({
    url:'/member/package/clothesCheck',
    method:'post',
    data:data
  })
}


//查询门店自营套餐
export function listPackageByStoreId(){
  return request({
    url:'/member/package/listPackageByStoreId',
    method:'get'
  })
}

//套餐Id获取衣物列表
export function getPackageClothes(id,useService){
  return request({
    url:'/clothes/service/getPackageClothes/'+ id +'/'+ useService,
    method:'get'
  })
}

//自营活动详情
export function getActiveByStore(){
  return request({
    url:'/member/activity/getActiveByStore/',
    method:'get'
  })
}


//抖音查询券接口
export function tikTok(params){
  return request({
    url:'/member/package/query/tikTok/',
    method:'get',
    params:params
  })
}
// 通过衣物ID查询衣物拍照示例详情
export function getPhotoExampleDetail(clothesId){
  return request({
    url:'clothes/photo/example/detail/clothesId/'+clothesId,
    method:'get',
  })
}
// 获取所有洗涤服务列表
export const getAllServiceList = () => {
  return request({
    url: '/clothes/service/all',
    method: 'get',
  })
}
// 收银端余额转通享卡
export const setCashierTradeBalance = (data) => {
  return request({
    url: '/member/storeUser/cashierTradeBalance',
    method: 'put',
    data
  })
}
// 门店返洗原因通过类型查询
export const getStoreRewashReason = (params) => {
  return request({
    url: '/sys/store_rewash_reason/list/by_type	',
    method: 'get',
    params
  })
}
