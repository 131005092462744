<template>
  <el-drawer
    class="drawer"
    :visible="payDrawer"
    direction="btt"
    size="100%"
    :show-close="false"
  >
    <template slot="title">
      <div class="drawer-title flex-center">
        确定支付
        <span class="close-icon" @click="handleClose">
          <i class="el-icon-close icon"></i>
        </span>
      </div>
    </template>
    <div class="drawer-content">
      <div class="product-table">
        <div style="flex: 7;">
          <div class="discountView" @click="showCouponList">
            <div class="top">
              <span class="title">
                <span class="el-icon-s-ticket icon"></span>
                <span class="text">使用优惠券</span>
              </span>
              <span class="el-icon-arrow-right icon2"></span>
            </div>
            <div class="bottom" v-if="couponList.length == 0">
              暂无优惠券可用
            </div>
            <div class="bottom" v-else>优惠券：{{ couponList.length }}张</div>
          </div>
          <div>
            <div class="toPayView">
              <ul>
                <li>
                  序号
                </li>
                <li>商品名称</li>
                <li>规格</li>
                <li>数量</li>
                <li>金额</li>
                <li>预计提成</li>
                <li>支付信息</li>
              </ul>
              <div class="listItem-box">
                <div
                  class="listItem"
                  v-for="(item, index) in selectedClothes"
                  :key="index"
                >
                  <div class="masterView">
                    <div>
                      {{ index + 1 }}
                    </div>
                    <div>
                      <span class="span-name">{{ item.goodsName }}</span>
                    </div>
                    <div class="nameView">
                      <span class="span-name">
                        {{ item.specification }}
                      </span>
                    </div>
                    <div class="nameView">
                      <span class="span-name"
                        >{{ item.list[2].value }}{{ item.orderGoodsUnit }}</span
                      >
                    </div>
                    <div class="nameView">
                      <span class="span-name">￥{{ item.list[4].value }}</span>
                    </div>
                    <div class="payTypeView">
                      <span class="label-tip">
                        ￥{{
                          userInfo.userType == "2"
                            ? item.memberCommissionPrice
                            : item.retailCommissionPrice
                        }}
                      </span>
                    </div>
                    <div class="payTypeView">
                      <label class="label-tip">
                        {{ item.payWayName }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="price-info">
            <div>
              <div style="color:#909399;padding-top:20px">
                <span style="padding-right:50px"
                  >原价: ¥ {{ (salePrice / 100).toFixed(2) }}</span
                >
              </div>
              <div style="color:#909399;padding-top:10px">
                <span style="padding-right:50px;"
                  >权益优惠: ¥ {{ (discountsPrice / 100).toFixed(2) }}</span
                >
              </div>
              <div style="color:#060606;padding-top:10px">
                <span style="padding-right:50px"
                  ><span>实付金额:</span>
                  <span style="color:#F90C2A;font-size:20px"
                    >¥ {{ (totalPrice / 100).toFixed(2) }}</span
                  ></span
                >
              </div>
            </div>
            <div>
              <div style="color:#909399;padding-top:20px">
                <span style="padding-right:20px"
                  >优惠金额: ¥ {{ (couponPrice / 100).toFixed(2) }}</span
                >
              </div>
              <div style="color:#909399;padding-top:10px">
                <span style="padding-right:20px;"
                  >优惠券: {{ couponName ? couponName : "无" }}</span
                >
              </div>
              <div style="color:#060606;padding-top:10px">
                <span style="padding-right:20px"
                  ><span>提成金额:</span>
                  <span style="color:#F90C2A;font-size:20px"
                    >¥ {{ (royaltyPrice / 100).toFixed(2) }}</span
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div
            style="
                  flex: 4;
                  border-left: 1px solid #e6e6e6;
                  margin-left: 10px;
                  margin-top: 15px;
                "
            v-if="couponActive"
          >
            <div class="couponList">
              <div class="couponList-title flex-between">
                <div class="flex-row">
                  <span class="line"></span>
                  <span>优惠券</span>
                </div>
                <div
                  class="el-icon-close"
                  @click="closeCoupon"
                  style="cursor: pointer;font-size:18px;font-weight:700"
                ></div>
              </div>
              <div class="couponBox">
                <el-row
                  @click.native="clickRadio"
                  class="nonuse-coupon"
                  :style="
                    radioValue
                      ? 'border: 1px solid rgba(34, 119, 246, 100)'
                      : ''
                  "
                >
                  <el-col class="left">不使用优惠券</el-col>
                  <el-col class="right">
                    <div
                      class="radio"
                      :class="radioValue ? 'select-radio' : ''"
                    >
                      <span v-if="radioValue" class="el-icon-check icon"></span>
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  class="coupon-content"
                  v-for="(item, index) in couponList"
                  :key="item.id"
                  :style="
                    item.radioValue
                      ? 'border: 1px solid rgba(34, 119, 246, 100)'
                      : ''
                  "
                >
                  <el-row
                    class="top"
                    @click.native="clickRadioList(item, item.id)"
                  >
                    <el-col class="left">
                      <div>
                        {{ item.couponTypeName }}
                        <span
                          style="margin-right: 10px;color: #1975FF;font-weight: 700"
                          >{{ item.discountTypeName }}</span
                        >
                      </div>
                      <div>{{ item.couponName }}</div>
                      <div>{{ item.beginTime }} 至 {{ item.endTime }}</div>
                    </el-col>
                    <el-col class="right">
                      <div
                        class="radio"
                        :class="item.radioValue ? 'select-radio' : ''"
                      >
                        <span
                          v-if="item.radioValue"
                          class="el-icon-check icon"
                        ></span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class="center"></el-row>
                  <el-row class="bottom" @click.native="clickRule(index)">
                    <el-col class="text">使用规则</el-col>
                    <el-col
                      v-if="!item.ruleShow"
                      class="el-icon-arrow-down icon"
                    ></el-col>
                    <el-col
                      v-if="item.ruleShow"
                      class="el-icon-arrow-up icon"
                    ></el-col>
                  </el-row>
                  <el-row
                    class="rule-box"
                    :class="item.ruleShow ? 'select-open' : 'select-close'"
                  >
                    <div v-if="item.ruleShow" style="font-size:14px">
                      {{ item.couponDesc }}
                    </div>
                  </el-row>
                </el-row>
              </div>
              <div class="couponList-footer">
                <el-button type="primary" @click="enterCoupon">确定 </el-button>
                <el-button @click="closeCoupon" class="close-button"
                  >取消
                </el-button>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="rightView">
        <div class="right-content">
          <div class="receivable-title">应收款</div>
          <div class="receivable-price">
            ¥ {{ (totalPrice / 100).toFixed(2) }}
          </div>
          <div class="line"></div>
          <div class="tView">支付方式</div>
          <div class="paymentView">
            <div
              class="paymentItem"
              v-for="(item, index) in pay_ways"
              :key="index"
              :style="
                item.bool
                  ? 'background-color:#EAF4FE;border: 1px solid #1975FF;'
                  : 'background-color:#fff;border:1px solid #fff;'
              "
              @click="choosePayWay(item, index)"
            >
              <div class="iconView">
                <label class="label-icon">{{ item.ico }}</label>
              </div>
              <div class="textView overflowText">
                <label class="label-name" v-if="item.name == '通享卡余额'"
                  >{{ item.name }}（{{
                    userInfo.headquartersBalance
                      ? userInfo.headquartersBalance
                      : 0
                  }}）
                </label>
                <label class="label-name" v-else-if="item.name == '历史余额'"
                  >{{ item.name }}（{{
                    userInfo.currStoreBalance ? userInfo.currStoreBalance : 0
                  }}）</label
                >
                <label class="label-name" v-else>{{ item.name }}</label>
                <label class="label-amt" v-if="item.bool">
                  应收：<span>{{ (totalPrice / 100).toFixed(2) }}</span>
                </label>
              </div>
              <div
                class="disabled-box"
                v-if="
                  item.name == '通享卡余额' &&
                    userInfo.headquartersBalance == '0'
                "
              ></div>
              <div
                class="disabled-box"
                v-else-if="
                  item.name == '历史余额' && userInfo.currStoreBalance == '0'
                "
              ></div>
              <div
                class="disabled-box"
                v-else-if="item.name != '现金' && totalPrice == 0"
              ></div>
            </div>
          </div>
          <div class="btnView">
            <el-button
              @click="payBtn"
              :disabled="!payType"
              :loading="buttonLoading"
              type="primary"
              style="width:360px"
              >结账</el-button
            >
          </div>
        </div>
        <div class="right-ransfer" v-show="transferBalanceShow">
          <div>
            <TransferBalance
              :userData="{
                balance: userInfo.currStoreBalance,
                userId: userInfo.userId,
                userName: userInfo.realName,
                phone: userInfo.phone,
                storeId: storeUserInfo.storeId,
              }"
              @close="closeTransferBalance"
              @enter="refreshData"
              @enterBack="enterTransferBalance"
            />
          </div>
        </div>
      </div>
      <transition name="paidFullscreen">
        <div
          id="qrCodePayPopView"
          class="popView"
          v-loading="qrCodePayView"
          :element-loading-text="qrCodePayViewName"
          v-if="payWay"
        >
          <div class="popView-bcView" @click="payWay = false"></div>
          <div class="popView-contentView">
            <div class="contentView">
              <div class="amtView">
                ¥
                <span>{{ (totalPrice / 100).toFixed(2) }}</span>
              </div>
              <div class="imgView">
                <img src="https://www.shouqianba.com/img/logo.svg" />
              </div>
              <div class="qrCodeInputView">
                <el-input
                  class="input-scanPayCode"
                  placeholder="请扫描或输入付款码"
                  autocomplete="off"
                  ref="pay"
                  v-model="payVal"
                  type="number"
                  autofocus="autofocus"
                ></el-input>
                <el-button @click="payBtn" type="primary">收款</el-button>
              </div>
              <div class="tipView">
                请使用支付宝扫码支付
                <p>支付过程中，请不要关闭该二维码！</p>
              </div>
            </div>
            <button class="btn-close" @click="payWay = false"></button>
          </div>
        </div>
      </transition>
    </div>
  </el-drawer>
</template>

<script>
import {
  getCouponuserList,
  addGoodsOrder,
  payGoodsOrder,
  queryPayGoodsOrder,
} from "@/api/commodity";
import { getUserInfo } from "@/api/store/user";
import TransferBalance from "@/view/commodityRetailing/retailing/components/transferBalance";
import { mapGetters } from "vuex";
export default {
  name: "paySuccess",
  components: {
    TransferBalance,
  },
  props: {
    payDrawer: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => {
        [];
      },
    },
    price: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectList: [],
      couponActive: false,
      couponList: [],
      radioValue: false,
      selectedClothes: [],
      pay_ways: [
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
          bool: false,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
      ],
      actuallyPrice: 0,
      // 付款码付款
      qrCodePayView: false,
      // 付款码提示
      qrCodePayViewName: "收款中...",
      payVal: "",
      payWay: false,
      salePrice: 0,
      discountsPrice: 0,
      royaltyPrice: 0,
      totalPrice: 0,
      couponPrice: 0,
      couponName: "",
      payType: null,
      transferBalanceShow: false,
      buttonLoading: false,
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      storeUserInfo: "userInfo",
    }),
  },
  watch: {
    payDrawer(value) {
      if (value) {
        this.userInfo = { ...this.user };
        this.refreshData();
        this.totalPrice = this.price;
        this.salePrice = 0;
        this.discountsPrice = 0;
        this.royaltyPrice = 0;
        this.selectedClothes = this.list.map((item) => {
          this.salePrice =
            this.salePrice + item.list[2].value * (item.salePrice * 100);
          if (this.userInfo.userType == "2") {
            this.discountsPrice =
              this.discountsPrice +
              item.list[2].value *
                (item.salePrice * 100 - item.vipSalePrice * 100);
          } else {
            this.discountsPrice = 0;
          }
          if (this.userInfo.userType == "2") {
            this.royaltyPrice =
              this.royaltyPrice +
              item.list[2].value * (item.memberCommissionPrice * 100);
          } else {
            this.royaltyPrice =
              this.royaltyPrice +
              item.list[2].value * (item.retailCommissionPrice * 100);
          }
          item.payWayName = "未支付";
          return item;
        });
        if (this.userInfo.userId) {
          this.getCouponListData();
        }
      } else {
        this.init();
        this.couponActive = false;
        this.transferBalanceShow = false;
        this.buttonLoading = false;
        this.qrCodePayView = false;
        this.payWay = false;
      }
    },
    totalPrice(value) {
      if (value == 0) {
        this.pay_ways = [
          {
            name: "通享卡余额",
            ico: "通",
            label: 11,
            bool: false,
          },
          {
            name: "历史余额",
            ico: "余",
            label: 3,
            bool: false,
          },
          {
            name: "现金",
            ico: "现",
            label: 4,
            bool: true,
          },
          {
            name: "收钱吧",
            ico: "收",
            label: 6,
            bool: false,
          },
        ];
        this.payType = 4;
        this.payWay = false;
        this.payVal = "";
      }
    },
    couponList: {
      handler(list) {
        this.totalPrice = this.price;
        this.couponPrice = 0;
        this.couponName = "";
        list.forEach((item) => {
          if (item.radioValue) {
            this.couponPrice = this.couponPrice + Number(item.money) * 100;
            this.couponName = this.couponName + item.couponName + ";";
          }
        });
        if (this.couponPrice < this.price) {
          this.totalPrice = this.totalPrice - this.couponPrice;
        } else {
          this.totalPrice = 0;
          this.couponPrice = this.price;
        }
      },
      deep: true,
    },
  },
  created() {},

  methods: {
    init() {
      this.pay_ways = [
        {
          name: "通享卡余额",
          ico: "通",
          label: 11,
          bool: false,
        },
        {
          name: "历史余额",
          ico: "余",
          label: 3,
          bool: false,
        },
        {
          name: "现金",
          ico: "现",
          label: 4,
          bool: false,
        },
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
      ];
      this.payType = null;
    },
    // 获取优惠券列表
    getCouponListData() {
      this.loading = true;
      getCouponuserList({ userId: this.userInfo.userId })
        .then(({ data }) => {
          if (data.code == 0) {
            this.couponList = data.data;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 关闭确定支付弹框
    handleClose() {
      this.$emit("closeDrawer");
    },
    showCouponList() {
      if (this.couponList.length != 0) {
        this.couponActive = !this.couponActive;
      }
    },
    //关闭优惠券列表
    clonesCoupon() {
      this.couponActive = false;
    },
    clickRadio() {
      if (this.radioValue == false) {
        this.couponList = this.couponList.map((item) => {
          item.radioValue = false;
          item.ruleShow = false;
          return item;
        });
      }
      this.radioValue = !this.radioValue;
    },
    // 选择优惠券
    clickRadioList(data, id) {
      if (!data.radioValue) {
        if (this.totalPrice == 0) {
          this.$message.error("金额不满足大于0元，无法使用:" + data.couponName);
          return;
        }
      }
      this.couponList = this.couponList.map((item) => {
        if (item.id == id) {
          item.radioValue = !item.radioValue;
        }
        return item;
      });
      this.radioValue = false;
    },
    clickRule(val) {
      this.couponList = this.couponList.map((item, index) => {
        if (index == val) {
          item.ruleShow = !item.ruleShow;
        }
        return item;
      });
    },
    // 清空选择的优惠券
    closeCoupon() {
      this.couponList = this.couponList.map((item) => {
        item.radioValue = false;
        item.ruleShow = false;
        return item;
      });
      this.couponActive = false;
      this.radioValue = false;
    },
    enterCoupon() {
      this.couponActive = false;
    },
    // 选择支付方式
    choosePayWay(data) {
      if (
        data.name == "通享卡余额" &&
        this.userInfo.headquartersBalance == "0"
      ) {
        return;
      }
      if (data.name == "历史余额" && this.userInfo.currStoreBalance == "0") {
        return;
      }
      if (this.totalPrice == 0 && data.name != "现金") {
        return;
      }
      if (data.label == 3) {
        if (this.totalPrice / 100 > Number(this.userInfo.currStoreBalance)) {
          this.$message.error("余额不足无法支付!");
          this.transferBalanceShow = true;
          return;
        }
      }
      if (data.label == 11) {
        if (this.totalPrice / 100 > Number(this.userInfo.headquartersBalance)) {
          this.$message.error("通享卡余额不足无法支付!");
          return;
        }
      }
      this.payType = data.label;
      this.pay_ways = this.pay_ways.map((item) => {
        item.bool = false;
        if (item.label == data.label) {
          item.bool = true;
        }
        return item;
      });

      if (data.label == 6) {
        this.payWay = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
    },
    // 创建订单
    payBtn() {
      let detailList = this.selectedClothes.map((item) => {
        return {
          goodsId: item.goodsId,
          goodsNum: item.list[2].value,
        };
      });
      let couponUserIdList = [];
      this.couponList.forEach((item) => {
        if (item.radioValue) {
          couponUserIdList.push(item.id);
        }
      });
      if (this.payType == 6) {
        this.qrCodePayView = true;
      } else {
        this.buttonLoading = true;
      }
      addGoodsOrder({
        userId: this.userInfo.userId,
        orderSource: 1,
        actuallyPaidPrice: this.totalPrice / 100,
        payWay: this.payType,
        orderRemark: "",
        detailList: detailList,
        couponUserIdList: couponUserIdList,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            if (this.payType == 6) {
              this.pay({
                goodsOrderId: data.data.goodsOrderId,
                authCode: this.payVal,
                payWay: this.payType,
              });
            } else {
              this.pay({
                goodsOrderId: data.data.goodsOrderId,
                payWay: this.payType,
              });
            }
          } else {
            this.$message.error(data.msg);
            this.buttonLoading = false;
            this.qrCodePayView = false;
          }
        })
        .catch(() => {
          this.buttonLoading = false;
          this.qrCodePayView = false;
        });
    },
    pay(obj) {
      payGoodsOrder({
        ...obj,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            if (obj.payWay == 6) {
              this.queryPay({
                outTradeNo: data.data.outTradeNo,
                payWay: obj.payWay,
                goodsOrderId: obj.goodsOrderId,
              });
              this.$message.success('订单支付成功！');
            } else {
              setTimeout(() => {
                this.$router.push("/home/more/commodityOrder");
              }, 1000);
              this.$message.success(data.data);
            }
          } else {
            if (obj.payWay == 6) {
              this.$message.error(data.data.msg);
            } else {
              this.$message.error(data.msg);
            }
            setTimeout(() => {
              this.$router.push("/home/more/commodityOrder");
            }, 1000);
          }
          this.buttonLoading = false;
          this.qrCodePayView = false;
        })
        .catch(() => {
          this.buttonLoading = false;
          this.qrCodePayView = false;
        });
    },
    // 查询是否支付
    queryPay(data) {
      queryPayGoodsOrder({
        ...data,
      })
        .then(({ data }) => {
          if (data.code != 0) {
            this.$message.error(data.msg);
          }
          setTimeout(() => {
            this.$router.push("/home/more/commodityOrder");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.$router.push("/home/more/commodityOrder");
          }, 1000);
        });
    },
    closeTransferBalance() {
      this.transferBalanceShow = false;
    },
    enterTransferBalance() {
      this.transferBalanceShow = false;
    },
    refreshData() {
      getUserInfo({ phoneOrCardNum: this.userInfo.phone }).then(({ data }) => {
        if (data.code == 0) {
          this.userInfo = {
            ...this.userInfo,
            headquartersBalance: data.data.memberUser.headquartersBalance
              ? data.data.memberUser.headquartersBalance
              : 0,
            currStoreBalance: data.data.memberUser.currStoreBalance
              ? data.data.memberUser.currStoreBalance
              : 0,
          };
          this.init();
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.drawer {
  ::v-deep .el-drawer__header {
    padding: 0;
    margin: 0;
  }
  .drawer-title {
    height: 70px;
    background-color: #fafafb;
    font-size: 22px;
    color: #101010;
    position: relative;
    .close-icon {
      position: absolute;
      top: 25px;
      left: 20px;
      .icon {
        color: #909399;
        font-weight: 700;
        font-size: 26px;
      }
    }
  }
  .drawer-content {
    height: calc(100vh - 70px);
    position: relative;
  }
  .discountView {
    cursor: pointer;
    color: #000;
    font-size: 14px;
    margin-top: 10px;
    width: 376px;
    height: 87px;
    border: 1px solid rgba(220, 223, 230, 100);
    border-radius: 5px;

    .top {
      width: 100%;
      height: 40px;
      background-color: rgba(250, 250, 251, 100);
      border-radius: 5px 5px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      .title {
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 24px;
        color: #3370ff;
      }
      .text {
        padding-left: 8px;
      }
      .icon2 {
        font-size: 20px;
        color: #101010;
      }
    }
    .bottom {
      height: 47px;
      line-height: 46px;
      font-size: 14px;
      padding-left: 14px;
    }
  }
  .couponList {
    position: relative;
    height: 100%;
    .couponList-title {
      padding: 0 15px;

      .line {
        width: 4px;
        background-color: #2277f6;
        margin-right: 10px;
        border-radius: 10px;
        height: 20px;
      }
    }
    .couponBox {
      height: calc(100vh - 260px);
      overflow: auto;
      font-size: 16px;
      margin: 20px 20px 0 20px;
      color: #000;
      .coupon-content {
        background-color: #fafafb;
        padding: 0 15px 15px;
        border: 1px solid rgba(220, 223, 230, 100);
        border-radius: 3px;
        margin-bottom: 20px;
        .top {
          padding-top: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            div {
              font-size: 14px;
            }
            div:nth-child(3) {
              color: #b2b2b2;
              padding: 3px 0;
            }
          }
        }

        .center {
          margin: 8px 0;
          height: 0;
          border-bottom: 1px dashed rgba(187, 187, 187, 100);
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          color: rgba(178, 178, 178, 100);
          font-size: 14px;
          padding-bottom: 5px;
          cursor: pointer;
          .icon {
            font-size: 18px;
            width: 20px;
          }
        }
      }
      .nonuse-coupon {
        background-color: #fafafb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding: 0 20px;
        border: 1px solid rgba(220, 223, 230, 100);
        border-radius: 3px;
        margin-bottom: 20px;
      }
      .right {
        height: 20px;
        width: 20px;
        .radio {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          border: 1px solid rgba(187, 187, 187, 100);
          background-color: rgba(255, 255, 255, 100);
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            font-size: 16px;
            color: #fff;
          }
        }
      }
      .select-radio {
        background: rgba(34, 119, 246, 100) !important;
        border: none !important;
      }
    }
    .couponList-footer {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      .close-button {
        border: 1px solid #dcdfe6;
      }
    }
  }
  .product-table {
    display: flex;
    padding-left: 20px;
    margin-right: 420px;
    padding-right: 20px;
    .toPayView {
      margin-top: 15px;

      ul {
        width: 100%;
        display: flex;
        padding: 15px 0;
        margin: 0;

        li {
          list-style: none;
          flex: 1;
          text-align: center;
          height: 28px;
        }
      }
      .listItem-box {
        height: calc(100vh - 400px);
        overflow: auto;
        border-bottom: 1px solid #e6e6e6;
        .listItem {
          background: #f8f8f8;
          margin-bottom: 10px;
          border-radius: 6px;

          .masterView {
            display: flex;
            padding: 20px 0px;

            > div {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            }

            > .nameView {
              > .span-name {
                font-family: PingFangSC-Semibold;
                font-size: 15px;
                color: #333;
                // margin-right: 15px;
              }

              .label-discount {
                margin-left: 5px;
                line-height: 16px;
                color: #ffb826;
                font-size: 14px;
                padding: 2px 6px;
                border-radius: 3px;
                background: rgba(237, 179, 57, 0.4);
                font-family: DINAlternate-Bold;
              }
            }

            > .amtView {
              // text-align: center;
              font-size: 14px;
              color: #333;
            }

            > .btnView {
              > button {
                flex: 1;
                height: 28px;
                font-size: 13px;
                color: #333;
              }

              > button.btn-dyqPay {
                background: #f8f8f8
                  url("~@/assets/navImages/Def_Icon_DYQPay_S.png") center / 24px
                  no-repeat;
              }
            }

            > .payTypeView {
              font-size: 14px;
              color: #333;

              > .label-tip {
                font-weight: 700;
                font-size: 16px;
                color: #ff5e56;
              }
            }
          }
        }

        > .listItem:last-child {
          margin-bottom: 0;
        }
      }
    }
    .price-info {
      display: flex;
      justify-content: flex-end;
    }
  }

  .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff;
    z-index: 10;
    width: 420px;
    height: calc(100vh - 70px);
    border-left: #e6e6e6 solid 1px;
    .right-content {
      margin: 20px 0;

      > .tView {
        position: relative;
        padding: 0 0 15px 35px;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333;
        line-height: 40px;
      }

      > .paymentView {
        padding: 0 30px 15px 30px;
        overflow-x: hidden;
        overflow-y: auto;

        .paymentItem {
          position: relative;
          display: flex;
          padding: 15px 0 15px 25px;
          border-radius: 5px;
          cursor: pointer;
          margin-bottom: 10px;
          .iconView {
            margin-right: 28px;
            height: 42px;

            > img {
              width: 42px;
              height: 42px;
            }

            > .label-icon {
              display: inline-block;
              border-radius: 6px;
              width: 42px;
              height: 42px;
              line-height: 42px;
              font-size: 18px;
              font-family: PingFangSC-Medium;
              color: #fff;
              text-align: center;
              background: #1975ff;
            }
          }

          .textView {
            display: flex;
            flex: 1;
            font-size: 15px;
            font-family: PingFangSC-Medium;
            color: #333;
            line-height: 42px;

            > .label-name {
              flex: 2;
            }

            > .label-amt {
              flex: 1;
              font-size: 13px;
              text-align: right;
              padding-right: 10px;
            }
          }
        }
        .disabled-box {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          border: 1px solid rgba(197, 193, 197, 0.5);
          background-color: rgba(197, 193, 197, 0.5);
          cursor: pointer;
        }
      }

      > .btnView {
        position: absolute;
        left: 30px;
        bottom: 40px;
      }
    }
    .right-ransfer {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100vh - 70px);
      width: 415px;
      background: #fff;
    }

    .receivable-title {
      color: rgba(137, 137, 137, 100);
      font-size: 18px;
      padding-left: 35px;
    }

    .receivable-price {
      color: rgba(40, 40, 40, 100);
      font-size: 25px;
      padding-left: 35px;
    }

    .line {
      height: 0;
      border: 1px solid rgba(230, 230, 230, 100);
      margin: 20px;
    }
  }
  #qrCodePayPopView {
    position: fixed;
    left: auto;
    top: 70px;
    right: 0;
    height: calc(100vh - 70px);
    z-index: 2000;
    background-color: #fff;

    > .popView-bcView {
      background: transparent;
    }

    > .popView-contentView {
      border-radius: 0;
      box-shadow: none;
      height: calc(100vh - 70px);
      width: 415px;
      right: 0;
      overflow: auto;
      .btn-close {
        position: absolute;
        top: 10px;
        right: 5px;
        width: 40px;
        height: 40px;
        border: none;
        background: url("~@/assets/navImages/Icon_X_Gray.png") center / 28px
          28px no-repeat;
      }
    }

    > .popView-contentView {
      > .contentView {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 350px;
        transform: translate(-50%, -50%);
        text-align: center;

        > .amtView {
          line-height: 50px;
          font-size: 32px;
          color: #ff5e56;

          > span {
            font-size: 36px;
            margin-left: 5px;
          }
        }

        > .imgView {
          margin-top: 20px;
          height: 46px;

          > img {
            width: 120px;
            height: 46px;
          }
        }

        > .qrCodeInputView {
          display: flex;
          padding: 30px 20px 20px 20px;

          > .input-scanPayCode {
            flex: 1;
          }
        }

        > .tipView {
          line-height: 20px;
          font-size: 14px;
          color: #b0b0b0;
        }
      }
    }
  }
}
//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  // transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
 {
  transform: translateX(10px);
  opacity: 0;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
